const footer__anchor = document.querySelectorAll('.footer-category__anchor');


footer__anchor.forEach(function(anchor){
    anchor.addEventListener('click', function(event){
        if(!event.target.matches(".footer-category__anchor"))   {
            return;
        }
        const target__anchor = event.target;
        const item__headline = target__anchor.closest('.item-headline');
        const item__list = item__headline.querySelector('.item-list');

        if (target__anchor.classList.contains('footer-category__anchor--active')) {
            target__anchor.classList.remove('footer-category__anchor--active');
            item__list.classList.remove('item-list--toggle');
        } else {
            target__anchor.classList.add('footer-category__anchor--active');
            item__list.classList.add('item-list--toggle');
        } 
    })
});

